
import DashboardRoutes from "@/modules/dashboard";
import CricketFightRoutes  from "@/modules/cricket-fight";
import SportsRoutes from "@/modules/sports";
import { createRouter, createWebHistory } from "vue-router";
import RaceRoutes from "@/modules/race";
import ProfileRoutes from "@/modules/profile";
import BetsRoutes from "@/modules/bets";
import GamesRoutes from "@/modules/games";
import MultiMarketRoutes from "@/modules/multi-market";
import AccountRoutes from "@/modules/account";
import WalletRoutes from "@/modules/wallet";
import BonusRoutes from "@/modules/bonus";
import MarketAnalysisRoutes from "@/modules/market-analysis";
import AuthorizationModule from '@/modules/authorization';
import MatkaRoutes from "@/modules/matka";
import ReferEarnRoutes from "@/modules/refer-earn";
const Home = () => import("../views/Home.vue");

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home
    },
      
    DashboardRoutes,
    SportsRoutes,
    RaceRoutes,
    ProfileRoutes,
    BetsRoutes,
    GamesRoutes,
    MultiMarketRoutes,
    AccountRoutes,
    WalletRoutes,
    BonusRoutes,
    MarketAnalysisRoutes,
    CricketFightRoutes,
    AuthorizationModule,
    MatkaRoutes,
    ReferEarnRoutes
];

export default createRouter({
    history: createWebHistory(),
    routes
});
