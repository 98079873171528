<template>
    <div v-if="bet" :class="bet?.selection_type=='back'?'back-slip':'lay-slip'" class="accordion-body team-con">
        <div class="team-con">
            <div class="team-list-sec">
                <ul>
                    <li class="text-fw">{{ translatedLangData('selection', 'Selection') }} <span>:</span></li>
                    <li class="team-list-wid text-val"> {{ bet.selection }}</li>
                </ul>
                <ul>
                    <li class="text-fw">{{ translatedLangData('selection-type', 'Selection Type') }}<span>:</span></li>
                    <li class="team-list-wid text-val">{{ bet.selection_type }}</li>
                </ul> 
                <ul>
                    <li class="text-fw">{{ translatedLangData('placing-time', 'Placing Time') }}<span>:</span></li>
                    <li class="team-list-wid text-val">
                        {{ convertUTCDateToLocalDateHere(bet.bet_timestamp_date) }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="team-list">
            <ul>
                <li>
                    <span class="text-fw">{{ translatedLangData('odds', 'Odds') }}</span> <br>
                    <span class="text-val">  {{ bet.odds }}</span>
                </li>
                <li v-if="bet.bet_market_type==3">
                    <span class="text-fw">{{ translatedLangData('rate', 'Rate') }}</span> <br>
                    <span class="text-val"> {{ bet?.selection_type=='back'?parseFloat(bet.pl/bet.stake):parseFloat((bet.liability / bet.stake) * 100) }}  </span>
                </li>
                <li class="">
                    <span class="text-fw">{{ translatedLangData('stake', 'Stake') }}</span> <br>
                    <span class="text-val"> {{ bet.stake }}</span>
                </li>
                <li v-if="bet.liability" class="">
                    <span class="text-fw">{{ translatedLangData('liability', 'Liability') }}</span> <br>
                    <span class="text-val"> {{ bet.liability }}</span>
                </li>
                <li v-if="bet.pl" class="border-0">
                    <span class="text-fw">{{ translatedLangData('pl', 'PL')}}</span> <br>
                    <span class="text-val"> {{ bet.pl }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>

import moment from 'moment';

export default{
    name:'BetsModal',
    inject:['translatedLangData'],
    methods:{
        convertUTCDateToLocalDateHere(timestamp) {
            return moment.unix(timestamp).format('DD MMM YYYY HH:mm:ss');
        }
    },
    props:['bet'] 
}

</script>

<style scoped>

.back-slip{  
  background-color: var(--back-bx-bg);
  border:3px solid #15739B;
  border-radius: 2px;
}

.lay-slip{  
  background-color: var(--lay-bx-bg);
  border:3px solid #E56275;
  border-radius: 2px;
}
.text-fw {
    font-weight: 600 !important;
}
.text-val {
    list-style: none;
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
}
.team-list-sec ul li span {
    display: inline-block;
    float: right;
    padding-right: 10px;
}
@media screen and (max-width: 479px){
    .team-list-sec ul li {
        font-size: 13px !important;
    }
    .text-val {
        font-size: 13px !important;
    }
}
</style>