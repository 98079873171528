<template>
    <div class="open-bets-modal">
        <div class="modal" ref="openBetsModal" id="openBets" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header open-bet-h">
                        <h5 class="modal-title" id="exampleModalLabel">{{ translatedLangData('open-bets', 'Open Bets') }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body open-bet-body">
                        <div class="searchForm">
                            <div class="row">
                                <div class="col-md-12">
                                    <label>{{ translatedLangData('type', 'Type') }}</label>
                                    <select class="form-select" @change="onChangeGameTypes($event)">
                                        <option v-for="item in getTypes()" :key="item.id" :value="item.id">{{ item.type }}</option>
                                    </select>
                                </div>

                                <div class="col-md-12 text-center">
                                    <button type="submit" @click="getOpenBetsServiceCall()" class="btn btn-gets">
                                        {{ translatedLangData('submit', 'Submit') }}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div v-if="averageDataList && averageDataList?.length != 0" class="outer-mobile-menu-con">
                            <div v-for="(bet, bet_index) in averageDataList" :key="bet_index" class="open-bet-body-wrapper">
                                <button class="open-bet-cmn-h" type="button" @click="clickOnViewBets(bet)">
                                    <span class="bet-marketname"> {{ bet?.event_name }} </span>
                                    <span class="bet-market-icons">
                                        <img src="@/assets/images/right-arrow-icons.png" alt="right-arrow">
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div v-else>
                            <NoDataFound></NoDataFound>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="settledBetsDetails" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog">
            <BetDetailsModal :openBets="selectedBet" :betType="selectedType"></BetDetailsModal>
        </div>
    </div>
</template>

<script>
import { convertUTCDateToLocalDate, getSubtractedDaysDate } from '@/shared/utils/date-time';
import NoDataFound from '@/shared/components/no-data/NoDataFound.vue';
import { Modal } from 'bootstrap';
import moment from 'moment';
import api from '../services/api';
import * as apiName from '../services/urls';
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import * as types from '@/modules/bets/utils/constants.js';
import BetDetailsModal from "../components/BetDetailsModal.vue"; 
const sports = JSON.parse(localStorage.getItem('sports_List'))?.non_custom || [];
const extractedSports = Array.isArray(sports) ? sports.slice(0, 3).map(obj => ({ id: obj.id, type: obj.name })) : [];

export default {
    name: "Bets",
    inject:['translatedLangData'],
    data() {
        return {
            filterOption: {
                type: 'sport',
                sportSubType: extractedSports?.[0]?.id,
                fromDate: moment(new Date(getSubtractedDaysDate(new Date(), 15))).format('YYYY-MM-DD'),
                toDate: moment(new Date()).format('YYYY-MM-DD')
            },
            openBetsList: null,
            groupingBetList: [],
            loading: false,
            non_custom_sports: JSON.parse(localStorage.getItem('sports_List')) ? JSON.parse(localStorage.getItem('sports_List')).non_custom : [],
            averageDataList:null,
            selectedBet: null,
            selectedType: null
        }
    },
    components: {
        NoDataFound,
        BetDetailsModal
    },
    mounted() {
        const modalElement = this.$refs.openBetsModal;
        modalElement.addEventListener('shown.bs.modal', () => {
            this.getOpenBetsServiceCall();
        });
    },
    computed: {
        formatNumber() {
            return (value) => {
                return parseFloat(value).toFixed(2);
            }
        },
        getAuthToken(){
            return this.$cookies.get(AUTH_TOKEN);
        }
    },
    methods: {
        getTypes() {
            const updatedGames = [...extractedSports, ...types.NEW_STATIC_GAMES.filter(game => game.id != 'all' && game.id != 'dw')];
            return updatedGames;
        },
        onChangeGameTypes(event) {
            const selectedValue = event.target.value;
            if(selectedValue == "1" || selectedValue == "2" || selectedValue == "4") {
                this.filterOption.type = 'sport';
                this.filterOption.sportSubType = Number(selectedValue);
            }else{
                this.filterOption.type = selectedValue;
                this.filterOption.sportSubType = null;
            }
        },
        getOpenBetsServiceCall() {
            this.loading = true;
            let authToken = this.getAuthToken;
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
            let data = {
                "type": this.filterOption.type,
                "sub_type": this.filterOption.sportSubType,
                "status": 'open',
                "page_entries": this.pageEntries,
                "from": this.filterOption.fromDate,
                "to": this.filterOption.toDate
            }
            api.post(apiName.OPEN_BETS_EVENT_LISTING, data, { headers }).then(response => {
                this.loading = false;
                this.openBetsList = [];
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.showErrorModalFunc(response?.data?.debug[0]);
                        }
                        else {
                            this.averageDataList = response?.data?.data?.data;
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.showErrorModalFunc(error[0]);
                }
            });
        },
        clickOnViewBets(item) {
            this.selectedBet = item;
            this.selectedType = this.filterOption.type;
            let viewBetsModal = new Modal('#settledBetsDetails')
            viewBetsModal.show();
        },
        convertUTCDateToLocalDateHere(timestamp) {
            return moment(convertUTCDateToLocalDate(timestamp)).format('DD/MM/YYYY hh:mm:ss');
        },
        convertUnixToLocalDateHere(timestamp) {
            return moment.unix(timestamp).format('DD MMM YYYY HH:mm:ss');
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        }
    }
}
</script>